import useDocumentTitle from "app/hooks/useDocumentTitle";
import { LeftButton } from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";
import DataImportRow from "settings/components/DataImportRow";
import FieldBoundariesUpload from "settings/components/FieldBoundariesUpload";
import YieldUpload from "settings/components/YieldUpload";
import { Container, ResponsiveHeading, StyledText } from "settings/styledComponents";

import { API_VERSION } from "lib/ajax";

import { Button } from "components/fl-ui";

const FIELD_BOUNDARIES = "fieldBoundaries";
const YIELD_DATA = "yieldData";

const DataImportExport = ({ initialModalId }) => {
  const navigate = useNavigate();
  const [activeModal, setActiveModal] = useState(() => {
    if (["fieldBoundaries", "yieldData"].includes(initialModalId)) {
      return initialModalId;
    } else {
      return null;
    }
  });
  const onClose = () => {
    navigate("/settings/data", { replace: true });
    setActiveModal(null);
  };
  useDocumentTitle("Data Settings");

  return (
    <>
      <Row>
        <Col xs={12}>
          <Container>
            <div>
              <ResponsiveHeading>Data import & export</ResponsiveHeading>
              <StyledText>Move information in or out of your account manually with files.</StyledText>
            </div>
          </Container>
        </Col>
      </Row>

      <DataImportRow
        copy="Zipped shapefiles contain the boundaries of your fields. Upload them here to import your field boundaries into Bushel Farm, or download to export a shapefile of all your boundaries in Bushel Farm."
        icon="fields"
        title="Field Boundary Shapefiles"
      >
        <LeftButton color="primary" hollow onClick={() => setActiveModal(FIELD_BOUNDARIES)}>
          Upload
        </LeftButton>
        <a className="btn btn-hollow btn-primary" href={`/${API_VERSION}/export/field_boundaries`}>
          Download
        </a>
      </DataImportRow>
      {activeModal === FIELD_BOUNDARIES && <FieldBoundariesUpload onClose={onClose} />}

      <DataImportRow
        copy="Upload zipped files from equipment displays to import yield, as-applied, or as-planted data generated by your machines."
        icon="harvesting"
        title="Equipment or Machine Data"
      >
        <Button color="primary" hollow onClick={() => setActiveModal(YIELD_DATA)}>
          Upload
        </Button>
      </DataImportRow>
      {activeModal === YIELD_DATA && <YieldUpload onClose={onClose} />}

      <DataImportRow
        copy="Export your fields, crops, activities, input applications, sales, and harvest loads as CSV files."
        icon="people"
        title="Account data"
      >
        <a className="btn btn-hollow btn-primary" href={`/${API_VERSION}/export/user_data`}>
          Download
        </a>
      </DataImportRow>
    </>
  );
};

DataImportExport.propTypes = {
  initialModalId: PropTypes.oneOf([FIELD_BOUNDARIES, YIELD_DATA]),
};

export default DataImportExport;
