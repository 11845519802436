import { makeVar, useReactiveVar } from "@apollo/client";
import { useEffect, useRef, useState } from "react";

import { login, logout } from "collection/graphql/auth/lib";
import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import useRestQuery from "hooks/useRestQuery";
import useWindow from "hooks/useWindow";
import { RegistrationFailedError, UnauthorizedError } from "lib/errors";
import { LOGIN_SIGNUP } from "lib/metrics/events";

/**
 * @typedef {Object} UserRegistration
 * @property {String} email
 * @property {String} first_name
 * @property {String} last_name
 * @property {String} password
 * @property {String} passwordConfirm
 * @property {String} phone
 * @property {String} postal_code
 */

const FORBIDDEN = 403;
const POLL_INTERVAL = 5 * 60 * 1000; // every 5 minutes
export const sharedState = makeVar({ isLoggingOut: false });

/**
 * Not intended to be used by anything other than {@link AuthContextProvider}.
 * @private
 */
const useAuthContext = () => {
  const { data, error, loading, refetch } = useRestQuery(GET_CURRENT_USER, { pollInterval: POLL_INTERVAL });
  const currentUser = data?.currentUser ?? null;
  const { isLoggingOut } = useReactiveVar(sharedState);
  const isLoggedIn = !!currentUser && !isLoggingOut;
  const win = useWindow();

  const [hasInvalidated, setHasInvalidated] = useState(false);
  const hasEverAuthenticated = useRef(isLoggedIn);
  useEffect(() => {
    if (!hasEverAuthenticated.current && isLoggedIn) {
      hasEverAuthenticated.current = true;
    } else if (hasEverAuthenticated.current && error?.networkError?.statusCode === FORBIDDEN) {
      setHasInvalidated(true);
    }
  }, [error, isLoggedIn]);

  return {
    currentUser,
    hasInvalidated,
    isLoggedIn,
    isLoggingOut,
    loading,

    /**
     * Fetches a password reset token.
     * @param {String} email
     * @return {Promise}
     */
    getPasswordResetToken: async (email) => {
      const response = await fetch("/v2.0/users/password/reset", {
        body: JSON.stringify({ email }),
        cache: "no-store",
        headers: { "Content-Type": "application/json" },
        method: "POST",
      });

      if (!response.ok) {
        throw new Error("An error occurred while requesting password reset token");
      }

      return response.json();
    },

    /**
     * Registers a new user.
     * @param {UserRegistration} userRegistration
     */
    register: async (userRegistration) => {
      const response = await fetch("/v2.0/users/register", {
        body: JSON.stringify(userRegistration),
        cache: "no-store",
        headers: { "Content-Type": "application/json" },
        method: "POST",
      });

      if (!response.ok) {
        LOGIN_SIGNUP.track({ email: userRegistration.email, outcome: "failure" });
        throw new RegistrationFailedError("User registration failed");
      }

      await refetch();
      LOGIN_SIGNUP.track({ email: userRegistration.email, outcome: "success" });
    },

    /**
     *
     * @param password
     * @param token
     * @return {Promise}
     */
    resetPassword: async ({ password, token }) => {
      const response = await fetch(`/v2.0/users/password/reset/${token}`, {
        body: JSON.stringify({ new_password: password }),
        cache: "no-store",
        headers: { "Content-Type": "application/json" },
        method: "POST",
      });
      const payload = await response.json();

      if (response.ok) {
        await refetch();
      } else if (payload?.error === "unauthorized") {
        throw new UnauthorizedError(payload.message);
      } else {
        throw new Error(payload);
      }
    },

    /**
     * @param {Object} credentials
     * @param {String} credentials.email
     * @param {String} credentials.password
     * @return {Promise}
     */
    login: async ({ email, password }) =>
      login(email, password).then(async () => {
        await refetch();
        win.location.reload();
      }),

    /**
     * @return {Promise}
     */
    logout: () => {
      sharedState({ isLoggingOut: true });
      return logout().finally(() => {
        win.location.href = "/";
      });
    },

    /**
     * Refreshes the auth query.
     */
    refetch,
  };
};

export default useAuthContext;
